<script>
import Page from "./page.vue";
export default {
  name: "NotAuthorized",
  extends: Page,
  data() {
    return {
      title: "401: UNAUTHORIZED",
      message: "Accesso alla risorsa non autorizzato.",
      action: this.go,
    };
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
  },
};
</script>
